<template>
  <div id="lom-events-create">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-tour name="myTour" :steps="TourSteps" :options="TourOptions"></v-tour>
    <v-card elevation="1" color="#F5F5F5">
      <v-dialog v-model="addDocumentRecordPrompt" persistent max-width="75%">
        <add-training-document
          pageTitle="Add Training Document"
          pageDescription="Add Training Document"
          :addDocumentRecordPrompt="addDocumentRecordPrompt"
          @hideAddDocumentRecordPrompt="addNewDocument($event)"
          v-if="addDocumentRecordPrompt"
        ></add-training-document>
      </v-dialog>
      <v-card-text>
        <v-container>
          <v-card elevation="3" color="#3a67b1" v-if="MemeberFullname != ''">
            <v-card-text>
              <v-row wrap>
                <v-col
                  align="center"
                  cols="12"
                  md="12"
                  class="mycard-title-2 fsize-5 fcolor-1"
                >
                  <!-- <p><span class="text-danger">*</span> indicates required field</p> -->
                  <p>Welcome {{ MemeberFullname }}, {{ CurrentDesignation }}</p>
                  <p>
                    {{ LomData.AreaName }} - {{ LomData.ZoneName }} -
                    {{ LomData.RegionName }} - {{ LomData.LomName }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="backPage"
                color="#EDBE38"
                elevation="30"
                shaped
                class="font-size-h6 fsize-3 mr-3 my-3 white--text"
              >
                <v-icon dark> mdi-arrow-left-circle </v-icon> &nbsp; Back
              </v-btn>
              <v-btn
                @click.prevent="refreshPageData"
                color="#EDBE38"
                elevation="30"
                shaped
                class="font-size-h6 fsize-3 mr-3 my-3 white--text"
              >
                <v-icon dark> mdi-refresh </v-icon> &nbsp; Refresh
              </v-btn>
            </v-col>
          </v-row>

          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>

          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Training Details &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon color="#F19A3E"> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span class="mycard-text2">
                    Fill in the date and time of the event and the venue
                    details.
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="12" md="12" lg="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">* </span>Was the Training
                        conducted?
                      </h6>
                    </label>
                    <v-switch
                      v-model="IsCompleted"
                      :label="`${IsCompleted == 1 ? 'Yes' : 'No'}`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3" v-if="IsCompleted == 1">
                    <h6 class="mycard-text1 fsize-3">
                      <span class="text-danger">*</span> Conducted Date
                    </h6>
                    <v-dialog
                      ref="dialog1"
                      v-model="DateModal1"
                      :return-value.sync="CompletedDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="CompletedDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:prepend>
                            <v-icon color="#2B9EB3"> mdi-calendar </v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="CompletedDate"
                        scrollable
                        :min="CompletedDateMin"
                        :max="CompletedDateMax"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="DateModal1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog1.save(CompletedDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />

            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                LO Training Answer &nbsp;
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    lg="4"
                    v-for="(Question, index) in Questions"
                    :key="index"
                  >
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span>
                        {{ Question.QuestionText }}
                      </h6>
                      <b class="p-0 m-0 text-danger">
                        {{
                          Question.MaxChar
                            ? "Only " +
                              Question.MaxChar +
                              " characters are allowed"
                            : ""
                        }}
                      </b>
                      <b class="p-0 m-0 text-danger">
                        {{
                          Question.MaxSize
                            ? "Upload Files " +
                              Question.MaxSize +
                              " MB are allowed"
                            : ""
                        }}
                      </b>
                    </label>
                    <v-text-field
                      v-if="Question.QuestionType == 1"
                      v-model="Question.Answer"
                      :rules="[
                        validateRequired,
                        Question.MaxChar
                          ? validateMaxChar(Question.MaxChar)
                          : null,
                      ]"
                      outlined
                      dense
                      required
                    ></v-text-field>
                    <div>
                      <v-textarea
                        v-if="Question.QuestionType == 2"
                        v-model="Question.Answer"
                        :rules="[
                          validateRequired,
                          Question.MaxChar
                            ? validateMaxChar(Question.MaxChar)
                            : null,
                        ]"
                        outlined
                        dense
                      ></v-textarea>
                    </div>
                    <v-select
                      v-if="Question.QuestionType == 3"
                      v-model="Question.Answer"
                      :rules="[validateOptions]"
                      :items="Question.OptionArray"
                      density="comfortable"
                    ></v-select>
                    <v-menu
                      v-if="Question.QuestionType == 4"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="Question.Answer"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="Question.Answer"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-file-input
                      v-if="Question.QuestionType == 5"
                      :rules="[
                        (value) =>
                          !value ||
                          value.size < Question.MaxSize + '000000' ||
                          'Image is required. Image size should be less than ' +
                            Question.MaxSize +
                            ' MB!',
                      ]"
                      accept="application/pdf"
                      prepend-icon="mdi mdi-paperclip"
                    ></v-file-input>
                    <v-menu
                      v-if="Question.QuestionType == 6"
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="Question.Answer"
                          label="Select Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="Question.Answer"
                        full-width
                        @click:minute="$refs.menu.save(Question.Answer)"
                      ></v-time-picker>
                    </v-menu>
                    <div v-if="Question.QuestionType === 7">
                      <v-radio-group
                        v-model="Question.Answer"
                        dense
                        :rules="[validateOptions]"
                      >
                        <v-radio
                          v-for="(option, index) in Question.OptionArray"
                          :key="index"
                          :label="option"
                          :value="option"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="Question.QuestionType == 8">
                      <v-checkbox
                        v-for="(option, index) in Question.OptionArray"
                        :key="index"
                        :label="option"
                        :value="option"
                        v-model="Question.Answer"
                      ></v-checkbox>
                    </div>
                    <v-text-field
                      v-if="Question.QuestionType == 9"
                      v-model="Question.Answer"
                      label="Enter a valid URL"
                      :rules="[validateUrl]"
                      placeholder="https://example.com"
                    ></v-text-field>
                    <div v-if="Question.QuestionType == 10">
                      <v-text-field
                        type="number"
                        label=""
                        outlined
                        dense
                        v-model="Question.Answer"
                        :rules="[
                          (value) => !!value || 'Must be a number',
                          (value) => !isNaN(value) || 'Must be a number',
                        ]"
                      ></v-text-field>
                    </div>
                    <div class="text-center" v-if="Question.QuestionType == 11">
                      <div class="rating-wrapper">
                        <div class="options">
                          <div
                            class="rating-option"
                            v-for="(option, index) in Question.OptionArray"
                            :key="index"
                          >
                            <label style="display: inline">{{ option }}</label>
                          </div>
                        </div>
                        <v-rating
                          background-color="indigo lighten-3"
                          color="indigo"
                          v-model="Question.Answer"
                          :dense="true"
                          :hover="true"
                          size="25"
                          class="rating-stars"
                        ></v-rating>
                      </div>

                      <div class="mt-2">
                        Selected Rating: {{ Question.Answer }}
                      </div>
                    </div>
                    <v-file-input
                      v-if="Question.QuestionType == 12"
                      :rules="[
                        (value) =>
                          !value ||
                          value.size < Question.MaxSize + '000000' ||
                          'Image is required. Image size should be less than ' +
                            Question.MaxSize +
                            ' MB!',
                      ]"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Avatar"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />

            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <upload-progress
                  :ProgessStart="progessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
                <br />
                <v-btn
                  id="step12"
                  type="submit"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#EDBE38"
                  class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                >
                  <v-icon dark> mdi-briefcase-check </v-icon> &nbsp; Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import AddTrainingDocument from "@/view/pages/erp/ldmt/AddTrainingDocument.vue";

export default {
  mixins: [common],
  components: {
    AddTrainingDocument,
  },
  data() {
    return {
      TooltipShow1: false,

      TourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
      TourSteps: [
        {
          target: "#step1",
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top",
          },
        },
        {
          target: "#step2",
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top",
          },
        },
        {
          target: "#step3",
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top",
          },
        },
      ],
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      OverlayLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,

      progessStart: 0,
      uploadPercentage: 0,

      JciYearCode: "",

      MemeberFullname: "",
      CurrentDesignation: "",

      Questions: [],

      LomCode: "",
      TrainingId: "",
      TrainingCategoryId: "",
      rows: {},
      LomData: {},

      validateMaxChar(max) {
        return (value) =>
          value && value.length <= max
            ? true
            : `Max ${max} characters are allowed.`;
      },
      validateMinSize(min) {
        return (value) =>
          value && value.length >= min
            ? true
            : `Min ${min} characters are required.`;
      },
      validateMaxSize(max) {
        return (value) =>
          value && value.length <= max
            ? true
            : `Answer must not exceed ${max} characters.`;
      },

      validateOptions: (value) => {
        return !!value || "Selecting one value is required.";
      },
      validateUrl: (value) => {
        if (!value) {
          return "URL is required.";
        }
        const urlPattern = new RegExp(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}/gm
        );
        return urlPattern.test(value) || "Please enter a valid URL.";
      },

      IsCompleted: 0,
      IsCompletedRules: [(v) => !!v || "Is Completed is required"],

      CompletedDate: "",
      // CompletedDate: LuxonDateTime.local().plus({days: 0}).toISO(),
      CompletedDateRules: [(v) => !!v || "Completed date is required"],
      DateModal1: false,

      CompletedDateMin: "",
      CompletedDateMax: "",

      EventPhotoImageLimit: 10,

      AllDocuments: [],
      uploadedEventImages: [],
      tableColumns2: [
        { text: "Document Type", value: "DocumentTypeName" },
        { text: "Date", value: "ActionDate" },
        { text: "Description", value: "DocumentDescription" },
        { text: "Image", value: "ImageUrl" },
        { text: "Action", value: "action" },
      ],

      Document: {},
      addDocumentRecordPrompt: false,

      UploadedFile1: {},

      PageInfo: [],
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.JciYearCode = tr.CurrentJciYearId;
        var YearName = tr.CurrentJciYearName;
        this.YearName = YearName;

        var MonthCode = this.$route.query.month;
        MonthCode = MonthCode == (null || undefined) ? 0 : MonthCode;
        console.log({ MonthCode });
        this.MonthCode = MonthCode;

        var tempDate1 = YearName + "-" + MonthCode + "-01";
        console.log({ tempDate1 });

        var tempDate2 = new Date(tempDate1);
        console.log({ tempDate2 });
        var MonthName = tempDate2.toLocaleString("en-us", { month: "long" });
        console.log({ MonthName });
        this.MonthName = MonthName;

        var MrfStartDate = tr.MrfStartDate;
        console.log({ MrfStartDate });

        // this.CompletedDateMin = MrfStartDate;
        // this.ToDateMin = MrfStartDate;

        var MrfEndDate = tr.MrfEndDate;
        console.log({ MrfEndDate });

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.FromDate = todayDate;
        this.ToDate = todayDate;

        // this.CompletedDateMax = MrfEndDate;
        // this.ToDateMax = MrfEndDate;

        this.pageData();
      }
    },
    rows: function () {
      console.log("watch rows");
      var tr = this.rows;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.IsCompleted = tr.CompletedStatus;
        this.CompletedDate = tr.CompletedDate;
        this.AllDocuments = tr.TrainingDocuments;
      }
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    startTour() {
      console.log("startTour called");
      var TourFlag = this.PageInfo.TourFlag;
      console.log({ TourFlag });
      if (TourFlag == 1) {
        var TourSteps = this.PageInfo.TourSteps;
        console.log({ TourSteps });
        // this.$tours["myTour"].start();
        introJs()
          .setOptions({
            steps: [
              {
                element: document.querySelector("#step1"),
                intro: "Select whether the event is one day or multi day event",
              },
              /*
              {
                element: document.querySelector("#step2"),
                intro: "Select event end date",
              },
              {
                element: document.querySelector("#step3"),
                intro: "Select event venue",
              },
              */
              {
                element: document.querySelector("#step4"),
                intro: "Select the portfolio suitable for each event",
              },
              {
                element: document.querySelector("#step5"),
                intro: "Select the category suitable for each event",
              },
              {
                element: document.querySelector("#step6"),
                intro: "Type the name of your event here",
              },
              {
                element: document.querySelector("#step7"),
                intro: "Choose the 100% efficiency, if your event is eligible",
              },
              {
                element: document.querySelector("#step8"),
                intro:
                  "Write a brief description about the event not exceeding 250 characters",
              },
              {
                element: document.querySelector("#step9"),
                intro:
                  "Upload event images in this section. JPEG and PNG file types are only permitted. Each file should not be more than 1 MB.",
              },
              {
                element: document.querySelector("#step10"),
                intro:
                  "Enter the dignitaries details who participated in the event.",
              },
              {
                element: document.querySelector("#step11"),
                intro:
                  "Upload the pdf document in this section. This is optional.",
              },
              {
                element: document.querySelector("#step12"),
                intro: "Click here to submit the event.",
              },
            ],
          })
          .start();
      }
    },
    addEventPhoto() {
      var n2 = this.AllDocuments.length;
      console.log({ n2 });
      var m2 = this.EventPhotoImageLimit;
      console.log({ m2 });
      if (n2 < m2) {
        this.addDocumentRecordPrompt = true;
      } else {
        var message = "Action photos maximum limit " + m2 + " reached. ";
        this.sweetAlert("error", message, false);
      }
    },
    refreshPageData() {
      console.log("pageData called");
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_events",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("refreshPageData called");

      var TrainingCategoryId = this.$route.query.v1;
      TrainingCategoryId =
        TrainingCategoryId == (null || undefined) ? "" : TrainingCategoryId;
      console.log({ TrainingCategoryId });
      this.TrainingCategoryId = TrainingCategoryId;

      var LomCode = this.$route.query.v2;
      LomCode = LomCode == (null || undefined) ? "" : LomCode;
      console.log({ LomCode });
      this.LomCode = LomCode;

      var TrainingId = this.$route.query.v3;
      TrainingId = TrainingId == (null || undefined) ? "" : TrainingId;
      console.log({ TrainingId });
      this.TrainingId = TrainingId;

      var LomName = this.$session.get("LomName");
      LomName = LomName == (null || undefined) ? "" : LomName;
      console.log({ LomName });
      this.LomName = LomName;

      var CurrentDesignation = this.$session.get("CurrentDesignation");
      CurrentDesignation =
        CurrentDesignation == (null || undefined) ? "" : CurrentDesignation;
      console.log({ CurrentDesignation });
      this.CurrentDesignation = CurrentDesignation;

      var MemeberFullname = this.$session.get("MemeberFullname");
      MemeberFullname =
        MemeberFullname == (null || undefined) ? "" : MemeberFullname;
      console.log("MemeberFullname=" + MemeberFullname);
      this.MemeberFullname = MemeberFullname;

      this.getLomDetails();
    },
    addNewDocument(document) {
      console.log("addNewDocument called");
      this.addDocumentRecordPrompt = false;
      console.log("document=" + JSON.stringify(document));
      this.Document = document;
      this.addNewRow(2);
    },
    addNewRow(tableId) {
      console.log("addNewRow called");

      console.log("tableId=" + tableId);

      switch (tableId) {
        case 2:
          var n2 = this.AllDocuments.length;
          console.log("n2=" + n2);
          var DocumentFlag = this.Document.DocumentFlag;
          console.log("DocumentFlag=" + DocumentFlag);
          var m2 = this.EventPhotoImageLimit;
          console.log({ m2 });
          if (n2 < m2) {
            if (DocumentFlag) {
              this.AllDocuments.push(this.Document);
            }
          } else {
            var message = "Event photos maximum limit " + m2 + " reached. ";
            this.sweetAlert("error", message, false);
          }
          this.Document = {};
          break;

        default:
          break;
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log({ tableId });
      console.log({ index });
      console.log({ tr });
      if (index >= 0) {
        switch (tableId) {
          case 2:
            this.AllDocuments.splice(index, 1);
            break;
          default:
            break;
        }
      }
    },
    getRecordDetails() {
      console.log("getRecordDetails called");
      var list_url = "api/lom-training/show";
      var where = {
        Category: this.TrainingCategoryId,
        LoTraining: this.TrainingId,
        Lo: this.LomCode,
      };
      console.log("list_url=" + list_url + ", where=" + JSON.stringify(where));
      this.getTableData("rows", list_url, where);
    },
    getLomDetails() {
      console.log("getLomDetails called");

      var LomCode = this.LomCode;
      console.log("LomCode=" + LomCode);

      if (LomCode > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/user-permission";
        var upload = {
          UserInterface: 1,
          Lom: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LomData = {};
        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.LomData = records;
              thisIns.getRecordDetails();
            } else {
              thisIns.sweetAlert("error", output, false);
              thisIns.$router.push("/lav/lom-status");
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "LO code should not be empty.";
        this.toast("error", message);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        /*
        var MemberTypeId = this.MemberTypeId;
        console.log("MemberTypeId=" + MemberTypeId);

        // Get Title details

        var TitleId = this.TitleCode;
        var TitleIndex = this.TitleCodeOptions.map((e) => e.value).indexOf(
          TitleId
        );
        console.log("TitleId=" + TitleId + ", TitleIndex=" + TitleIndex);
        var TitleName = this.TitleCodeOptions[TitleIndex].text;
        console.log("TitleName=" + TitleName);
        */
        var htmlTxt = "";
        // htmlTxt += "<br/> Title <b> " + TitleName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var EventData = {
          Lom: this.LomCode,
          TrainingCategory: this.TrainingCategoryId,
          LomTraining: this.TrainingId,
          IsCompleted: this.IsCompleted ? 1 : 0,
          CompletedDate: this.IsCompleted ? this.CompletedDate : null,
          Documents: this.AllDocuments,
          Questions: this.Questions,
        };

        console.log("EventData=" + JSON.stringify(EventData));

        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("Form", JSON.stringify(EventData));
        upload.append("UploadedFile1", this.UploadedFile1);

        var FileUpload = false;
        console.log("FileUpload=" + FileUpload);

        if (FileUpload) {
          var n2 = this.AllDocuments.length;
          var m2 = 0;
          for (var j = 0; j < n2; j++) {
            var uploadedImageFlag = this.AllDocuments[j].DocumentFlag;
            console.log(
              "j=" +
                j +
                ", m2=" +
                m2 +
                ", uploadedImageFlag=" +
                uploadedImageFlag
            );
            if (uploadedImageFlag) {
              var uploadedImageId = "ActionPhoto" + m2;
              var tempDocument = this.AllDocuments[j];
              var uploadedImageFile = tempDocument.UploadedImage;
              var UploadedImageName = tempDocument.UploadedImageName;
              console.log({ tempDocument });
              // var uploadedFile = this.$refs[refName].files[0]
              upload.append(
                uploadedImageId,
                uploadedImageFile,
                UploadedImageName
              );
            }
            m2++;
          }
        }

        console.log("upload=" + JSON.stringify(upload));

        this.progessStart = 1;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.OverlayLoadingFlag = false;

            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.$router.push("/lav/lom-status");
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", output, false);
      }
    },
    getQuestionData() {
      console.log("getQuestionData called");

      var TrainingCategoryId = this.$route.query.v1;
      TrainingCategoryId =
        TrainingCategoryId == (null || undefined) ? "" : TrainingCategoryId;
      console.log({ TrainingCategoryId });
      this.TrainingCategoryId = TrainingCategoryId;

      this.rows = [];

      if (TrainingCategoryId != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training-question/lists";
        var upload = {
          UserInterface: 1,
          Category: this.TrainingCategoryId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.rows = [];
        this.SearchFlag = true;
        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.Questions = records.TableData;
              // thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Year should not be empty.";
        console.log("error=" + message);
      }
    },
  },

  beforeMount() {
    this.refreshPageData();
    this.getQuestionData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
  mounted: function () {
    // this.$tours["myTour"].start();
    // this.startTour();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#lom-events-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  #faculty-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 12px;
      color: red;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  .mycard-head-title {
    // padding-bottom: 0;
    font-size: 20px;
    font-family: sans-serif;
    padding: 20px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-1 {
    font-family: sans-serif;
    padding: 10px;
    text-align: left;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-2 {
    font-family: "Roboto", serif;
    padding: 10px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-text1 {
    font-family: sans-serif;
    // text-transform: capitalize;
  }
  .mycard-text2 {
    font-size: 20px;
    font-family: "Merriweather", serif;
    color: red;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
  .mycard-text4 {
    font-size: 20px;
    font-family: "EB Garamond", serif;
    font-weight: bold;
    font-style: italic;
  }
  .mycard-text5 {
    font-size: 20px !important;
    font-family: "Roboto", serif;
    color: white;
  }
  .mycard-head-subtitle {
    padding-bottom: 0;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-family: sans-serif;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-family: sans-serif;
    font-size: 14px;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .note-text1 {
    margin-left: 2rem;
    font-size: 14px;
    padding: 5px;
  }
  .fsize-1 {
    font-size: 12px !important;
  }
  .fsize-2 {
    font-size: 14px !important;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .fsize-5 {
    font-size: 20px !important;
  }
  .fcolor-1 {
    color: #fff !important;
  }
  .bg-color-1 {
    background-color: #3a67b1;
  }
  .bg-color-2 {
    background-color: #0097d7;
  }
  .bg-color-3 {
    background-color: #edbe38;
  }
  .bg-color-4 {
    background-color: #56bda3;
  }
}
</style>